import React from "react";
import { Box } from "@mui/material";

export default function About() {
  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        justifyContent: "center",
        color: "#e0e1dd",
      }}
    >
      <Box>
        I'm a software engineer co-founding{" "}
        <a
          href="https://www.predicate.io"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          {" "}
          Predicate
        </a>
        , a seed-stage startup bridging the gap between traditional finance and
        public blockchains.
        <br />
        <br />
        In the past, I've worked on a variety of projects including developer
        relationships at{" "}
        <a
          href="https://www.jpmorgan.com"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          JPMorgan
        </a>
        , on & offramps at{" "}
        <a
          href="https://ponto.org/"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          Ponto
        </a>
        , and partnerships at{" "}
        <a
          href="https://www.celo.org"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          Celo
        </a>
        .
        <br />
        <br />
        Talk to me about music, connecting people, and good lighting.
      </Box>
    </Box>
  );
}
